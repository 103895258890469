.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

label {
	display: block;
	margin-top: 10px;
}

.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.container,
.container-fluid {
	margin-top: 20px;
}

td.hexId {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
}

.rowSpace {
	margin-top: 9px;
}
.rowSpace2 {
	margin-top: 13px;
}

.hexId {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
}

.hexIdGray {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	color: #a0a0a0;
}
.hexIdBio {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	color: orange;
}

.hexIdCent {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	text-align: center;
}

input[type='text'].hexId {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
}

td {
	vertical-align: top;
}

th {
	background-color: #f6f6f6;
}

h4 {
	color: #a0a0a0;
}

td.fitwidth {
	width: 1px;
	white-space: nowrap;
}

td.aliasId {
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	color: #1a73e8; /* 8d550b; */
	font-weight: bold;
}

.statusOk {
	color: black;
}
.statusErr {
	color: rgb(215, 58, 73);
}

.valMain {
	color: #1a73e8; /* 8d550b ; */
	font-family: 'Helvetica';
	font-size: 12pt;
	font-weight: 600;
}

.valMainReplayed {
	color: #e81a99; /* 8d550b ; */
	font-family: 'Helvetica';
	font-size: 12pt;
	font-weight: 600;
}
.valMainHex {
	color: #1a73e8;
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	font-weight: bold;
}
.valMainText{
	color: black;
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 10.5pt;
	white-space: nowrap;
	font-weight: lighter;
}
.h4Replayed {
	color: #e81a99;
}

.valPass {
	color: rgb(152, 102, 51);
	font-family: 'Helvetica';
	font-size: 12pt;
	font-weight: 600;
}

.valMainOld {
	color: #1a73e8; /* 8d550b ; */
	font-family: 'Helvetica';
	font-size: 12pt;
	font-style: italic;
}

.valPassOld {
	color: rgb(152, 102, 51);
	font-family: 'Helvetica';
	font-size: 12pt;
	font-style: italic;
}

.valMainLight {
	color: #1a73e8; /* 8d550b ; */
	font-family: 'Helvetica';
	font-size: 12pt;
	font-weight: 500;
}

.valMainOk {
	color: rgb(0, 153, 0);
}
.valMainhex {
	color: rgb(192, 192, 192);
}

.scoreOk {
	color: rgb(0, 153, 0);
}
.scoreWarn {
	color: orange;
}
.scoreWarn {
	color: rgb(215, 58, 73);
}

.valMainWarn {
	color: orange;
}
.valMainErr {
	color: rgb(215, 58, 73);
}

.valMainUndef {
	color: rgb(220, 220, 220);
}

input.inputOk {
	color: black;
}
input.inputErr {
	color: rgb(215, 58, 73);
}

.inputLabel {
	font-weight: bold;
	font-size: 12pt;
}

.inputValEmpty {
	font-size: 12pt;
	color: black;
}

.inputValOk {
	font-size: 12pt;
	color: rgb(0, 153, 0);
}

.inputValErr {
	font-size: 12pt;
	color: rgb(215, 58, 73);
}

a:link.wavOff {
	display: none;
}
a:link.wavOn {
	display: inline;
	text-decoration: none;
}

/*

#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  #react-paginate li {
    display: inline-block;
  }
  .pagination {
    color: #0366d6;
  }
  .break-me {
    cursor: default;
  }
 */
